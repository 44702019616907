import React from "react";
import ArticleImage from "./ArticleImage";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";

function CustomCarousel(props) {
  const items = props.items || [];
  return (
    <Carousel>
      {items.map((item, index) => (
        <ArticleImage
          key={index}
          image={item.image}
          caption={item.caption}
        />
      ))}
    </Carousel>
  );
}

export default CustomCarousel;
import { Divider } from "@mui/material";
import React from "react";
import awsReinvent2024 from "../../assets/images/aws-reinvent-2024/aws-reinvent-2024.png";
import bellagio from "../../assets/images/aws-reinvent-2024/bellagio.jpg";
import bellagio2 from "../../assets/images/aws-reinvent-2024/bellagio2.jpeg";
import talk from "../../assets/images/aws-reinvent-2024/talk.jpeg";
import talk2 from "../../assets/images/aws-reinvent-2024/talk2.jpeg";
import talk3 from "../../assets/images/aws-reinvent-2024/talk3.jpeg";
import keynote from "../../assets/images/aws-reinvent-2024/keynote.jpeg";
import keynote2 from "../../assets/images/aws-reinvent-2024/keynote2.jpeg";
import s3bucket from "../../assets/images/aws-reinvent-2024/s3bucket.jpeg";
import replay from "../../assets/images/aws-reinvent-2024/replay.jpeg";
import Article from "../../components/Article";
import CustomCarousel from "../../components/CustomCarousel";

export const AwsReinvent2024Details = {
  articleTitle: "AWS re:Invent 2024",
  articleDate: "2024-12-19",
  articleAuthor: "Harrison Cannon",
  articleImage: awsReinvent2024,
  articleBody: "What recent innovations were announced at AWS re:Invent this year? How would I summarise my experience? How did I prepare as a first-time speaker? Let me tell you...",
  articleSlug: "aws-reinvent-2024"
};

function AwsReinvent2024() {
  function ArticleContent() {
    return (
      <div>
        <h2>Background</h2>

        <p>
          For anyone that is unaware, AWS re:Invent is an annual tech convention held in Las Vegas, NV where thousands of organisations and technology enthusiasts  attend to learn about the latest announcements and releases from AWS. With the main theme changing on yearly basis, the underlying theme remains to be one of knowledge-sharing and professional growth. With that, customers, partners, and hobbyists all share their latest innovative solutions with AWS technologies at their center.
        </p>

        <Divider className="divider" />

        <h2>My Experience</h2>

        <h3>Las Vegas</h3>

        <p>
          As you can imagine, spending a week in Las Vegas is an experience in itself. Everywhere you look, there&apos;s either an unusual sight which you&apos;d probably not see on the streets of Manchester or signs for casinos, drinks, and food.
        </p>

        <p>
          But, and I&apos;m sure other attendees would agree, you have to give the casino a shot! After all, you are in Sin City.
        </p>

        <p>
          Top tip: study a map before travelling to help you get your bearings for when you arrive.
        </p>

        <CustomCarousel
          items={[
            { image: bellagio, caption: "View from Bellagio" },
            { image: bellagio2, caption: "View from Bellagio" }
          ]}
        />

        <h3>My Talk: Platform Engineering Excellence: Strategy & Technology</h3>

        <p>
          Whilst this year was my second year attending, it was also my first time as a Speaker. I landed this opportunity by being a Community Builder on the <a href="https://aws.amazon.com/developer/community/community-builders/" target="_blank" rel="noreferrer">AWS Community Builder programme</a>.
        </p>

        <p>
          At the time, I was working on a Platform Engineering solution for my employer, and the technical innovations my team and I had made, were worth sharing. I submitted a preliminary talk proposal and waited anxiously for a few weeks. I distinctly remember constantly checking my emails and one day, there it was: a pending invitation just waiting to be accepted.
        </p>

        <p>
          Over the weeks that followed, I worked with my employer to decide on what specific topics I should talk about as well as condensing down the large platform so that it could be talked about within the 20 minute lightning talk slot. Despite the talk being level 300<sup>1</sup>, I rehearsed my talk with various people, making sure it was appealing to varying job roles and levels.
        </p>

        <p>
          <i><sup>1</sup>Level 300 assumes proficiency in the topic and typically cover in-depth architecture, development, deployment, and migration examples. The content explores how services can be used and potential customer use cases, offering recommendations and best practices for experienced AWS users.</i>
        </p>

        <p>
          You can catch the recording of my talk on <a href="https://www.youtube.com/watch?v=tMhi9UrP2H8" target="_blank" rel="noreferrer">YouTube</a>.
        </p>

        <CustomCarousel
          items={[
            { image: talk, caption: "Talk agenda" },
            { image: talk2, caption: "Wide shot of my talk" },
            { image: talk3, caption: "Talk introduction" }
          ]}
        />

        <h3>Keynotes</h3>

        <p>
          Throughout the week, there are several keynotes by key members of the AWS Leadership Team. Each one delves into the recent innovations available from AWS as well as case studies from customers and partners where AWS has been used to solve a real-world business problem.
        </p>

        <p>
          For every talk, there is a different entry way. Last year, it was a cover band, this year was a DJ. - Just adds to the overall AWS re:Invent experience.
        </p>

        <p>
          This years notable takeaways from Matt Garman&apos;s (AWS CEO) Keynote:

          <ul>
            <li>
              <a href="https://aws.amazon.com/blogs/aws/introducing-amazon-nova-frontier-intelligence-and-industry-leading-price-performance/" target="_blank" rel="noreferrer">Amazon Nova</a>
            </li>
            <li>
              <a href="https://aws.amazon.com/blogs/aws/new-amazon-q-developer-agent-capabilities-include-generating-documentation-code-reviews-and-unit-tests/" target="_blank" rel="noreferrer">Amazon Q Developer Agents</a>
            </li>
            <li>
              <a href="https://aws.amazon.com/blogs/aws/introducing-queryable-object-metadata-for-amazon-s3-buckets-preview/" target="_blank" rel="noreferrer">S3 Tables</a>
            </li>
          </ul>
        </p>

        <CustomCarousel
          items={[
            { image: keynote, caption: "Main Keynote" },
            { image: keynote2, caption: "Main Keynote" }
          ]}
        />

        <h3>Expo</h3>

        <p>
          As with the AWS Summits, AWS re:Invent has an exhibition hall where partners and customers can showcase their recent AWS innovations and offerings. This is a great opportunity to learn about new technologies and rapidly-scaling organisations.
        </p>

        <p>
          Right in the center of the expo hall is the AWS Village. This is where AWS showcase their own services and offerings. As with the whole event, everyone is super friendly...
        </p>

        <CustomCarousel
          items={[
            { image: s3bucket, caption: "My yearly S3 bucket photo" }
          ]}
        />

        <p>
          To make the learning more interactive and memorable, AWS often host activities so you can see a real-world use case of AWS technologies, making it easier for you to apply these innovations to your business problems.
        </p>

        <p>
          For example, there was a Football Penalty Shootout activity where participants kicked a football into a net as hard as they could. AWS would then calculate the speed and accuracy of your penalty using various Internet of Things (IoT) devices. Your placement on a leaderboard turned any professional relationship into a competition!
        </p>

        <h3>Events</h3>

        <p>
          Lots of AWS Partners and Customers organise and host events in the evenings after the event. These offer the opportunity to network with people you may have crossed paths with, or with people you&apos;ve never met before. Either way, you&apos;ll be sure to hit it off. And if not, I&apos;m sure the free food and drinks will help break the ice!
        </p>

        <p>
          Top tip: If an event appeals to you, make sure you RSVP ahead of time to avoid disappointment as they fill up quick!
        </p>

        <h3>AWS re:Play</h3>

        <p>
          Another great addition to the week&apos;s events is AWS re:Play. This is a huge event, which is included in your AWS re:Invent pass, is situated away from the main Las Vegas strip. But with unlimited food, drinks, and entertainment from both global and local artists, this is an event not to be missed.
        </p>

        <p>
          Travel to and from the event is covered by AWS with their continued use of shuttle buses. As AWS re:Play is towards the end of AWS re:Invent, AWS kindly make the return route of the buses visit each of the large hotels covered on the AWS hotel list.
        </p>

        <CustomCarousel
          items={[
            { image: replay, caption: "AWS re:Play" }
          ]}
        />

        <h3>Closing Thoughts</h3>

        <p>
          From the AWS announcements, events, talks, to overall experience, AWS re:Invent is an opportunity not to be missed. Whether you&apos;re in the need to showcase your recent innovation, learn from others, or network, making the trip to AWS re:Invent is definitely worthwhile.
        </p>
      </div>
    );
  }

  return <Article
    article={AwsReinvent2024Details}
    content={ArticleContent}
  />;
}

export default AwsReinvent2024;